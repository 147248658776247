<template>
  <section class="relative isolate w-full overflow-hidden bg-black">
    <Transition
      enter-active-class="transition-opacity duration-500 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <NuxtImg
        v-if="
          (blok.feature_image && blok.feature_image.filename) || !showCarousel
        "
        provider="storyblok"
        sizes="xs:100svw sm:100svw md:100svw lg:100svw xl:100svw xxl:100svw"
        class="animate-grow absolute inset-0 z-0 h-full w-full object-cover opacity-70"
        :height="imageDimensions.height"
        :width="imageDimensions.width"
        :modifiers="{
          smart: true,
          fit: 'crop',
          width: imageDimensions.width,
          height: imageDimensions.height,
          filters: {
            focal: blok.feature_image.focus,
          },
        }"
        :src="blok.feature_image.filename"
        :alt="blok.feature_image.alt"
        fetchpriority="high"
        preload
        placeholder
        loading="eager"
      />
    </Transition>
    <Transition
      enter-active-class="transition-opacity duration-500 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in"
      leave-from-class="opacity-0"
      leave-to-class="opacity-100"
    >
      <div
        v-if="blok.images && blok.images.length > 1 && showCarousel"
        class="absolute inset-0 z-[1] h-full w-full bg-black"
      >
        <ClientOnly>
          <UCarousel
            v-slot="{ item }"
            :items="blok.images"
            :autoplay="{ delay: 4000 }"
            loop
            fade
            class="h-full w-full"
            :ui="{
              root: 'h-full w-full',
              viewport: 'h-full w-full',
              container: 'h-full w-full ms-0',
              item: 'h-full w-full ps-0',
            }"
          >
            <NuxtImg
              :src="item.filename"
              :alt="item.alt"
              sizes="xs:100svw sm:100svw md:100svw lg:100svw xl:100svw xxl:100svw"
              :modifiers="{
                smart: true,
                fit: 'crop',
                width: imageDimensions.width,
                height: imageDimensions.height,
                filters: {
                  focal: blok.feature_image.focus,
                },
              }"
              :width="imageDimensions.width"
              :height="imageDimensions.height"
              placeholder
              loading="lazy"
              class="animate-grow h-full w-full object-cover opacity-70"
            />
          </UCarousel>
        </ClientOnly>
      </div>
    </Transition>
    <div
      class="absolute inset-0 z-[2] bg-gradient-to-b from-transparent via-neutral-950/75 to-neutral-950"
    />

    <div
      v-if="hasValidLocationParam"
      class="relative isolate z-[4] mx-auto max-w-[var(--breakpoint-2xl)]"
    >
      <UTooltip
        :text="route.query.location.replaceAll('_', ' ')"
        :ui="{ content: 'capitalize font-bold ml-6 sm:ml-18 text-yellow-400' }"
      >
        <UButton
          variant="subtle"
          icon="i-lucide-arrow-left-circle"
          class="absolute top-4 left-6 cursor-pointer sm:top-8 sm:left-8"
          :to="`/locations/${route.query.location.replaceAll('_', '-')}`"
        >
          View Location
        </UButton>
      </UTooltip>
    </div>
    <div
      class="relative z-[3] mx-auto grid max-w-[var(--breakpoint-2xl)] items-center gap-8 overflow-visible px-6 py-12 sm:px-8 sm:py-16 md:py-20 lg:grid-cols-5 lg:py-28 xl:py-36 2xl:py-40"
    >
      <!-- Copy -->
      <div
        class="col-span-1 mt-16 mb-24 max-w-prose md:mt-8 lg:col-span-3 xl:mt-20 xl:mb-36"
      >
        <div
          class="text-base font-bold text-yellow-400 uppercase text-shadow-lg"
        >
          {{ type }}
        </div>
        <h1
          class="mt-0.5 text-3xl font-extrabold text-white text-shadow-lg sm:text-5xl"
        >
          <span v-if="type !== 'Booking Confirmation'">
            {{ blok.headline }}
          </span>
          <span v-else>{{ name }}</span>
        </h1>
        <p
          class="mt-2 text-lg font-medium text-neutral-300 text-shadow-lg sm:text-xl"
        >
          <span v-if="type !== 'Booking Confirmation'">
            {{ blok.lead }}
          </span>
          <span v-else>
            Hey there! We cannot wait to welcome you to {{ name }}. Here you can
            find all the information you need to make your Live For Today
            adventure a success.
          </span>
        </p>
        <div
          v-if="
            blok.hero_link &&
            blok.hero_link.title &&
            type !== 'Booking Confirmation'
          "
          class="mt-1"
        >
          <UButton
            v-if="blok.hero_link.linktype !== 'story'"
            variant="link"
            class="-ml-2"
            size="xl"
            :target="blok.hero_link.target"
            trailing-icon="i-lucide-arrow-right"
            :to="blok.hero_link.url"
          >
            {{ blok.hero_link.title }}
          </UButton>
          <UButton
            v-else
            variant="link"
            class="-ml-2"
            size="xl"
            :target="blok.hero_link.target"
            trailing-icon="i-lucide-arrow-right"
            :to="`/${blok.hero_link.cached_url}`"
          >
            {{ blok.hero_link.title }}
          </UButton>
        </div>
        <div v-if="type === 'Booking Confirmation'" class="mt-1">
          <UButton
            variant="link"
            class="-ml-2"
            size="xl"
            target="_blank"
            trailing-icon="i-lucide-arrow-right"
            to="/legal/safety-waivers"
          >
            Complete Your Safety Waivers
          </UButton>
        </div>
      </div>
      <div
        v-if="
          blok.secondary_image &&
          blok.secondary_image.filename &&
          !blok.hero_carousel
        "
        class="relative col-span-1 flex h-[300px] items-center justify-center overflow-visible lg:col-span-1 lg:col-start-5"
      >
        <NuxtImg
          provider="storyblok"
          sizes="xs:100svw sm:100svw md:100svw lg:50svw xl:40svw xxl:30svw"
          class="h-full w-[120%] object-contain"
          :src="blok.secondary_image.filename"
          :alt="blok.secondary_image.alt"
          fetchpriority="high"
          preload
          placeholder
          loading="eager"
        />
      </div>
      <div
        v-if="blok.hero_carousel"
        class="relative col-span-1 -mt-16 pb-16 lg:col-span-2 lg:mt-0 lg:pb-8"
      >
        <ClientOnly>
          <UCarousel
            v-slot="{ item }"
            :items="blok.hero_carousel"
            :autoplay="{ delay: 6000 }"
            loop
            arrows
            dots
            prev-icon="i-lucide-chevron-left"
            next-icon="i-lucide-chevron-right"
            :ui="{
              container: 'transition-[height]',
              controls: 'absolute -bottom-8 inset-x-12',
              dots: '-top-7',
              dot: 'w-6 h-1',
            }"
            class="mx-auto h-full lg:w-[300px] lg:max-w-xs"
          >
            <UCard
              :ui="{
                root: 'h-full w-full overflow-hidden aspect-6/4 sm:aspect-video lg:aspect-4/6 ring-0 border-2 border-yellow-400/75',
                body: 'p-0 sm:p-0 isolate relative h-full bg-black',
              }"
            >
              <NuxtImg
                provider="storyblok"
                sizes="xs:100svw sm:100svw md:100svw lg:50svw xl:40svw xxl:40svw"
                :modifiers="{
                  smart: true,
                  fit: 'cover',
                  width: imageDimensions.width,
                  height: imageDimensions.height,
                  filters: {
                    focal: item.image.focus,
                  },
                }"
                fetchpriority="high"
                preload
                placeholder
                loading="eager"
                :src="item.image.filename"
                :alt="item.image.alt"
                class="animate-grow absolute inset-0 z-0 h-full w-full object-cover object-center"
              />
              <div
                class="absolute right-0 bottom-0 left-0 z-[2] h-full w-full bg-gradient-to-b from-transparent via-neutral-950/75 to-neutral-950"
              />
              <UBadge
                v-if="item.badge"
                :icon="
                  item.badge === 'package'
                    ? 'i-lucide-ticket-percent'
                    : item.badge === 'offer'
                      ? 'i-lucide-badge-percent'
                      : item.badge === 'activity'
                        ? 'i-lucide-zap'
                        : item.badge === 'gift'
                          ? 'i-lucide-gift'
                          : item.badge === 'event'
                            ? 'i-lucide-calendar-days'
                            : 'i-lucide-megaphone'
                "
                size="lg"
                color="neutral"
                class="absolute top-4 right-4 rounded-full bg-black/75 p-1.5 text-yellow-400 ring-2 ring-yellow-400/75 backdrop-blur"
              />
              <div class="absolute right-4 bottom-0 left-4 z-[2] pb-4">
                <div class="space-y-4 pt-4">
                  <div class="flex items-center justify-between gap-4">
                    <div>
                      <p
                        class="text-sm font-bold text-yellow-400 uppercase text-shadow-lg"
                      >
                        {{ item.subtitle }}
                      </p>
                      <h2
                        class="text-lg leading-tight font-extrabold text-white text-shadow-lg"
                      >
                        {{ item.title }}
                      </h2>
                    </div>
                    <div class="mt-auto">
                      <p
                        class="ml-auto text-right text-sm font-semibold text-neutral-300 text-shadow-lg"
                      >
                        From
                      </p>
                      <p
                        class="-mt-1 text-2xl font-bold tracking-wide text-white text-shadow-lg"
                      >
                        £{{ item.price }}
                      </p>
                    </div>
                  </div>
                  <UButton
                    variant="subtle"
                    :label="item.button.title"
                    class="w-full justify-center"
                    trailing-icon="i-lucide-chevron-right"
                    :to="`/${item.button.cached_url}`"
                  />
                </div>
              </div>
            </UCard>
          </UCarousel>
        </ClientOnly>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';

const route = useRoute();
const { width: windowWidth } = useWindowSize();

const allowedLocations = [
  'ripley_castle_activity_centre',
  'hazlewood_castle_activity_centre',
  'swinton_estate_activity_centre',
  'harrogate_climbing_centre',
  'yorkshire_adventures',
]; // define your allowed locations

const hasValidLocationParam = computed(() => {
  const location = route.query.location;
  return location !== undefined && allowedLocations.includes(location);
});

defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
});

const imageDimensions = computed(() => {
  // md breakpoint is 768px
  if (windowWidth.value < 768) {
    return { width: 1080, height: 1080 };
  }
  return { width: 1920, height: 1080 };
});

const showCarousel = ref(false);

// Force carousel reinitialization on mount
onMounted(() => {
  // Trigger a small delay to ensure the carousel is properly mounted
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 100);
  setTimeout(() => {
    showCarousel.value = true;
  }, 4000);
});
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate-grow {
  animation: pulse 14s ease-in-out infinite;
}
</style>
